import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Layout from "./layout";
import { extendTheme } from "@chakra-ui/react";

export const wrapPageElement = ({ element }) => {
  const theme = extendTheme({
    colors: {
      gray: {
        50: "#ebf3fb",
        100: "#d4d9df",
        200: "#bac0c4",
        300: "#a0a7ac",
        400: "#858e93",
        500: "#6c747a",
        600: "#535a5f",
        700: "#3b4145",
        800: "#21272b",
        900: "#050e15",
      },
      gcsblue: {
        50: "#e7eaff",
        100: "#bac1f8",
        200: "#8d98f1",
        300: "#5f6eeb",
        400: "#3546e5",
        500: "#1f2ccc",
        600: "#17239f",
        700: "#0f1871",
        800: "#070e45",
        900: "#01051b",
      },
    },
    fonts: { heading: "Poppins", body: "Poppins" },
  });
  return (
    <ChakraProvider theme={theme}>
      <Layout>{element}</Layout>
    </ChakraProvider>
  );
};
